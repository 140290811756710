import _path from "path";
var exports = {};
const path = _path;

function isUrlRequest(url, root) {
  // An URL is not an request if
  // 1. It's an absolute url and it is not `windows` path like `C:\dir\file`
  if (/^[a-z][a-z0-9+.-]*:/i.test(url) && !path.win32.isAbsolute(url)) {
    return false;
  } // 2. It's a protocol-relative


  if (/^\/\//.test(url)) {
    return false;
  } // 3. It's some kind of url for a template


  if (/^[{}[\]#*;,'§$%&(=?`´^°<>]/.test(url)) {
    return false;
  } // 4. It's also not an request if root isn't set and it's a root-relative url


  if ((root === undefined || root === false) && /^\//.test(url)) {
    return false;
  }

  return true;
}

exports = isUrlRequest;
export default exports;